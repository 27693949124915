var site = window.site || {};
var openSalesforceChat = window.openSalesforceChat || function () {};

(function ($) {
  Drupal.behaviors.liveChatServiceDrawer = {
    attach: function (context) {
      var $lcServiceDrawer = $('.js-lc-service-drawer-v2', context);
      var $pageOverlay = $('.js-lc-service-drawer-overlay-v2', context);
      var $liveChat = $('.js-live-chat', context);
      var $orderStatusBtn = $('.js-lc-order-status-btn', context);
     
      function closeLcServiceDrawer() {
        $lcServiceDrawer.slideUp();
        $pageOverlay.slideUp();
      }
      function openLcServiceDrawer() {
        $lcServiceDrawer.slideDown();
        $pageOverlay.slideDown();
        if (typeof site.track !== 'undefined' && typeof site.track.evtLink === 'function') {
          site.track.evtLink({
            event_name: 'live chat interaction',
            event_category: 'live chat interaction',
            event_action: 'chat offered',
            event_label: 'click'
          });
        }
      }

      $liveChat.once().on('click', function (e) {
        e.stopPropagation();
        e.preventDefault();
        if ($lcServiceDrawer.is(':visible')) {
          closeLcServiceDrawer();
          return;
        }
        openLcServiceDrawer();
      });

      $pageOverlay.once().on('click', function (e) {
        e.stopPropagation();
        closeLcServiceDrawer();
      });

      $('html')
        .once()
        .on('click', function (e) {
          if (
            !$(e.target).hasClass('js-lc-service-drawer') &&
            !$(e.target).hasClass('js-live-chat')
          ) {
            closeLcServiceDrawer();
          }
        });
      
      $orderStatusBtn.once().on('click', function (e) {
        e.preventDefault();
        closeLcServiceDrawer();
        openSalesforceChat();
        if (typeof site.track !== 'undefined' && typeof site.track.evtLink === 'function') {
          site.track.evtLink({
            event_name: 'live chat interaction',
            event_category: 'live chat interaction',
            event_action: 'chat type selection',
            event_label: 'Customer Service'
          });
        }
      });
    }
  };
})(jQuery, Drupal);
